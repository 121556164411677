<template>
  <div class="rule">
    <Top name="交易规则" back email v-if="topShow"></Top>
    <div class="rule_w">
      <div class="rule_item">
        <h2>交易规则</h2>
        <div class="rule_item_c">
          <p>
            游戏交易采用商品转移的形式进行，不需要买卖平台账号，购买商品后，系统自动将商品内容转移到购买账号下。（出售单个角色不影响账号下其它角色的正常使用）
          </p>
        </div>
      </div>
      <div class="rule_item">
        <h2>购买流程</h2>
        <div class="rule_item_c">
          <!-- <img src="../../assets/images/rule_img1.jpg" alt=""> -->
          <p>我要购买—浏览商品—下单购买—完成支付—登录查收商品</p>
          <h3>买家须知</h3>
          <p>
            1、购买商品需先登录账号。<br />
            2、购买角色直接转入您购买的账号，登录游戏进入对应区服即可查收角色。<br />
            3、交易的仅是角色及其它商品，不涉及账号。<br />
            4、虚拟交易，不支持无理由退换货。<br />
            5、交易过程中有任何问题可联系客服。
          </p>
        </div>
      </div>
      <div class="rule_item">
        <h2>出售流程</h2>
        <div class="rule_item_c">
          <!-- <img src="../../assets/images/rule_img2.jpg" alt=""> -->
          <p>我要出售—填写信息—提交审核—出售成功—获得余额</p>
          <h3>卖家须知</h3>
          <p>
            1、商品上架后需经历审核期、公示期、出售期。<br />
            2、审核期：上架登记后需经过人工审核，过审后才可成功上架，审核期一般不超过24小时。<br />
            3、公示期：角色为{{ ruleData.publicityPeriod }}小时，货币为{{
              ruleData.currencyPublicityPeriod
            }}小时，装备为{{
              ruleData.equipmentPublicityPeriod
            }}分钟，期间商品只做展示，不可购买。<br />
            4、出售期：出售期为{{
              ruleData.salePeriod
            }}天，到期未出售则自动下架。<br />
            5、角色商品提交审核后，相应角色将变为封禁状态，无法登录。<br />
            <!-- 6、商品出售后平台将收取部分信息费。信息费（角色）=交易额*{{ruleData.sellingInformation}}%，最低为{{ruleData.informationMin}}元，最高为{{ruleData.informationMax}}元；信息费（货币）=交易额*{{ruleData.currencySellingInformation}}%，最低为{{ruleData.currencyInformationMin}}元，最高为{{ruleData.currencyInformationMax}}元；信息费（装备）=交易额*{{ruleData.equipmentSellingInformation}}%，最低为{{ruleData.equipmentInformationMin}}元，最高为{{ruleData.equipmentInformationMax}}元。<br /> -->
            6、商品出售后平台将收取部分信息费，具体信息费用请查看出售的上架规则。<br />
            7、出售所得金额24小时内为考察期，后转为正常。
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Top from "@/components/top.vue";

export default {
  name: "Rule",
  components: {
    Top,
  },
  data() {
    return {
      topShow: true,
      ruleData: {
        sellingInformation: 0,
        informationMin: 0,
        informationMax: 0,
        publicityPeriod: 0,
        salePeriod: 0,
        currencySellingInformation: 0,
        currencyInformationMin: 0,
        currencyInformationMax: 0,
        currencyPublicityPeriod: 0,
        equipmentSellingInformation: 0,
        equipmentInformationMin: 0,
        equipmentInformationMax: 0,
        equipmentPublicityPeriod: 0,
      },
    };
  },
  created() {
    //获取路由参数
    this.params = this.$route.query;
    //有tag参数是app访问，需去掉头部
    if (this.params.tag) {
      this.topShow = false;
    } else {
      this.topShow = true;
    }
    //获取交易规则
    this.getRule();
  },
  methods: {
    //获取交易规则信息
    getRule() {
      this.$api.sale.getRule().then((res) => {
        if (res.code == 200) {
          this.ruleData = res.data;
        }
      });
    },
  },
};
</script>
<style scoped>
.rule {
  min-height: 100%;
  background-color: #ececec;
}
.rule_w {
  padding: 0.28125rem 0;
  text-align: left;
}
.rule_item {
  background-color: #fff;
  border-top: 1px solid #dedede;
  border-bottom: 1px solid #dedede;
  padding: 0.4375rem 0;
  font-size: 0.8125rem;
  line-height: 1.375rem;
  color: #707070;
  margin-bottom: 0.4375rem;
}
.rule_item h2 {
  line-height: 1.8125rem;
  color: #ff4e00;
  position: relative;
  padding: 0 0.84375rem 0 1.40625rem;
  border-bottom: 1px dashed #b3b3b3;
}
.rule_item h2::before {
  content: "";
  width: 0;
  height: 0;
  border: 4px solid transparent;
  border-left-color: #ef4034;
  position: absolute;
  left: 0.84375rem;
  top: 50%;
  transform: translate(0, -50%);
}
.rule_item .rule_item_c {
  padding: 0.9375rem 0.84375rem;
}
.rule_item_c img {
  width: 95%;
  display: block;
  margin: 0 auto;
}
.rule_item_c h3 {
  color: #313131;
  margin: 0.1875rem 0;
  font-weight: 500;
}
</style>
